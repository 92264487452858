/**
 * *Rôle : Liste les vidéos par catégories
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'gatsby';
import 'moment/locale/fr';
import moment from 'moment';
import H2 from '../functional/H2';
import PlaceHolder from '../functional/LoadingPlaceHolder/LoadingPlaceHolderVideos';
import Loader from '../functional/Loader';

const ListLastVideos = (props) => {
	const [listVideos, setListVideos] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasmore] = useState(false);
	const [currentPage, setCurrentpage] = useState(1);
	const [getMoreVideo, setGetMoreVideo] = useState(false);
	const [idCat, setIdCat] = useState();
	const [titleCat, setTitleCAt] = useState();
	const [ready, setReady] = useState(true);

	useEffect(() => {
		if (idCat !== props.idCat) {
			setReady(false);
			setIdCat(props.idCat);
			setListVideos([]);
			setCurrentpage(1);
		}
	}, [idCat, props.idCat]);

	useEffect(() => {
		setLoading(true);
		axios
			.get(
				`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAllVideoByCategory?idCategory=${props.idCat}&per_page=26&page=${currentPage}`
			)
			.then(function (res) {
				setGetMoreVideo(false);

				// si get more data est demandé on concatène
				if (getMoreVideo) {
					const newList = listVideos.concat(res.data.data);
					setListVideos(newList);
				} else if (!getMoreVideo) {
					setListVideos(res.data.data);
				}

				props.getTitleCategory(res.data.data[0].parent_folder.name); //Transmission du titre au parent
				setTitleCAt(res.data.data[0].parent_folder.name);
				setLoading(false);
				//test si il reste des éléments a charger
				if (res.data.paging.next === null) {
					setHasmore(true);
				} else if (res.data.paging.next) {
					setHasmore(false);
				}
				setReady(true);
			})
			.catch(function (err) {
				// console.log('error get Vimeo', err)
				setLoading(true);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, props.idCat]);

	const getMoreData = () => {
		setCurrentpage(currentPage + 1);
		setGetMoreVideo(true);
	};

	let buttonLoadMore;

	if (!hasMore) {
		buttonLoadMore = (
			<button
				onClick={() => getMoreData()}
				className="mt-5 w-full text-secondary hover:text-primary uppercase animate font-semibold p-6"
			>
				charger plus ...
			</button>
		);
	} else {
		buttonLoadMore = (
			<div className="flex items-center justify-center mt-5 w-full text-gray-400 uppercase animate p-6 text-xxs">
				Il n'y a plus d'autre vidéos dans cette catégorie
			</div>
		);
	}

	const createUri = (defaultUri) => {
		const uri = defaultUri.replace('/videos/', '');
		return uri;
	};

	let listeVideo;
	if (listVideos && listVideos.length > 0) {
		listeVideo = listVideos.map((video, index) =>
			!video.is_playable || video.privacy.view === 'password' ? (
				<div key={index}></div>
			) : (
				<Link to={`/watch-video/${createUri(video.uri)}`} key={index} className="mb-5">
					<div className="w-full mb-5 lg:mb-0">
						<div className="w-full relative">
							<div className="relative overflow-hidden rounded-t-xl rounded-b-md h-44 max-h-44 w-full bg-white">
								<ProgressiveImage
									delay={500}
									src={video.pictures.sizes[3].link}
									placeholder={video.pictures.sizes[2].link}
								>
									{(src, loading) => (
										<img
											className="absolute top-0 left-0 w-full h-full hover-img-scale animate"
											style={{
												filter: loading ? 'blur(5px)' : 'blur(0px)',
												objectFit: 'cover',
												objectPosition: 'center center'
											}}
											src={src}
											alt=""
										/>
									)}
								</ProgressiveImage>
							</div>
							<svg
								className="h-10 w-10 rounded-full p-3 bg-gray-100 absolute -bottom-4 left-5 text-secondary"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								stroke="currentColor"
								fill="#6c62af"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<polygon points="5 3 19 12 5 21 5 3" />
							</svg>
						</div>
					</div>

					<div className="text-gray-400 text-sm mt-5 flex items-center justify-start">
						<svg
							className="h-3 w-3 mr-2"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<rect x="3" y="4" width="18" height="18" rx="2" ry="2" />{' '}
							<line x1="16" y1="2" x2="16" y2="6" />{' '}
							<line x1="8" y1="2" x2="8" y2="6" />{' '}
							<line x1="3" y1="10" x2="21" y2="10" />
						</svg>
						{moment(video.created_time).format('LL')}
					</div>
					<div className="font-bold text-lg">{video.name}</div>
				</Link>
			)
		);
	}

	if (!ready) {
		return (
			<div className="bg-gray-100 p-10">
				<div className="flex items-center justify-center">
					<Loader />
				</div>
			</div>
		);
	} else {
		return (
			<>
				<div className="bg-gray-100 px-2 sm:px-5 lg:px-10 p-10">
					<H2 className="mb-16">
						<span className="block">Toutes les vidéos</span>
						<span className="text-base">{titleCat ? `"${titleCat}"` : <></>}</span>
					</H2>

					<div className="grid grid-col-1 md:grid-cols-2 gap-5 mb-0">{listeVideo}</div>

					{loading ? <PlaceHolder /> : <></>}

					{buttonLoadMore}
				</div>
			</>
		);
	}
};

export default ListLastVideos;
