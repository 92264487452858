/**
 * Watch Single Video
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import StickyBox from 'react-sticky-box';
import axios from 'axios';
import ProgressiveImage from 'react-progressive-image';
import 'moment/locale/fr';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { playVideo } from '../../redux/playVideo/actionPlayVideo';
import Seo from '../seo';
import Layout from '../body/layout';
import Container from '../body/Container';
import flowers from '../../images/svg/flowers-bg.svg';
import ShareInLine from '../marketing/social/ShareInLine';
import ContainerWithSideBarre from '../body/ContainerWithSideBarre';
import ContentLeft from '../body/ContentLeft';
import ContentRight from '../body/ContentRight';
import SideBar from '../marketing/sideBar/SideBar';
import ListeLastVideos from '../content/ListeLastVideos';
import ListeCategory from '../content/ListeCategoriesVideos';
import Loader from '../functional/Loader';
import ListeVideosByCatRandom from '../content/ListeVideosByCatRandom';

const WatchSingleVideo = (props) => {
	const dispatch = useDispatch();

	const { id } = props;
	const [loading, setLoading] = useState(false);
	const [currentVideo, setCurrentVideo] = useState();
	// const [playVideo, setPlayVideo] = useState(false)

	useEffect(() => {
		setLoading(true);
		axios
			.get(
				`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getSingleVideo?videoId=${id}`
			)
			.then(function (res) {
				setCurrentVideo(res.data);
				setLoading(false);
			})
			.catch(function (err) {
				console.log('error get Vimeo', err);
				setLoading(false);
			});
	}, [id]);

	// Récupération de l'id de la catégorie courante
	let idCategoryCurrent;
	if (currentVideo && currentVideo.parent_folder) {
		idCategoryCurrent = currentVideo.parent_folder.uri.replace(
			`/users/${process.env.GATSBY_VIMEO_USER_ID}/projects/`,
			''
		);
	}

	const PlayVideo = () => {
		// Redux State Open Window Video
		dispatch(
			playVideo({
				id: id,
				open: true
			})
		);
	};

	return (
		<>
			<Layout header="heroPost">
				<Seo title={currentVideo ? currentVideo.name : 'videos nader abou anas'} />

				{/* Header Post */}
				<div className="header relative">
					<div
						className="absolute z-0 w-full h-full opacity-20"
						style={{ backgroundImage: `url(${flowers})`, backgroundSize: '30%' }}
					></div>

					<Container className="relative z-10" paddingNull={true}>
						<div className="absolute z-10 top-5 left-5">
							<Link
								to={'/videos'}
								className="bg-white bg-opacity-20 text-white px-5 py-1 rounded-sm hover:bg-primary animate flex items-center"
							>
								<svg
									className="h-4 w-4 mr-1"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="currentColor"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" />{' '}
									<line x1="5" y1="12" x2="19" y2="12" />{' '}
									<line x1="5" y1="12" x2="9" y2="16" />{' '}
									<line x1="5" y1="12" x2="9" y2="8" />
								</svg>
								<div>Retour</div>
							</Link>
						</div>

						{currentVideo && !loading ? (
							<ProgressiveImage
								delay={500}
								src={currentVideo.pictures.sizes[5].link}
								placeholder={currentVideo.pictures.sizes[2].link}
							>
								{(src, loading) => (
									<>
										<img
											className="w-full h-full"
											style={{
												filter: loading ? 'blur(3px)' : 'blur(0px)',
												objectFit: 'cover',
												objectPosition: 'center center'
											}}
											src={src}
											alt=""
										/>
										{loading ? (
											<></>
										) : (
											<>
												<div className="absolute top-0 left-0 bg-black bg-opacity-40 hover:bg-opacity-20 w-full h-full animate"></div>
												<button
													onClick={() => PlayVideo()}
													className="absolute left-1/2 bg-white hover:bg-secondary text-secondary hover:text-white bg-opacity-75 rounded-full p-10 hover-ride-top-large animate"
													style={{
														bottom: 'calc(50% - 50px)',
														left: 'calc(50% - 50px)'
													}}
												>
													<svg
														className="h-10 w-10 absolute bottom-5 left-6"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														strokeWidth="2"
														stroke="currentColor"
														fill="#6c62af"
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<polygon points="5 3 19 12 5 21 5 3" />
													</svg>
												</button>
											</>
										)}
									</>
								)}
							</ProgressiveImage>
						) : (
							<div className="flex items-center justify-center h-96">
								<Loader color={'#ffffff'} />
							</div>
						)}
					</Container>
				</div>

				{/* Title Post */}
				<Container className="relative mt-0 md:-mt-16 z-20" paddingNull={true}>
					<div className="mx-0 md:mx-10 p-10 bg-primary text-white">
						{currentVideo && !loading ? (
							<>
								<div
									// data-aos="fade"
									className="flex items-center text-gray-400"
								>
									<svg
										className="h-4 w-4 mr-2"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
										/>
									</svg>
									{moment(currentVideo.created_time).format('LL')}
								</div>
								<h1
									// data-aos="fade"
									className="text-xl flex flex-col items-start justify-center m-0 p-0"
								>
									{currentVideo.name}
								</h1>
							</>
						) : (
							<div className="flex items-center justify-center">
								<Loader color={'#ffffff'} />
							</div>
						)}
					</div>
				</Container>

				<Container>
					{/* Social Share */}
					<ShareInLine urlMedia={`/watch-video/${id}`} />

					{currentVideo && currentVideo.parent_folder ? (
						<ListeVideosByCatRandom idCategoryCurrent={idCategoryCurrent} />
					) : (
						<></>
					)}

					<ContainerWithSideBarre>
						<StickyBox
							offsetTop={20}
							offsetBottom={20}
							className="w-full lg:w-8/12 stickyBox"
						>
							<ContentLeft backgroundDisplay={false}>
								<ListeLastVideos />
							</ContentLeft>
						</StickyBox>

						<StickyBox
							offsetTop={20}
							offsetBottom={20}
							className="w-full lg:w-4/12 stickyBox"
						>
							<ContentRight>
								<ListeCategory />
								<SideBar modCategoriesDisplay={false} />
							</ContentRight>
						</StickyBox>
					</ContainerWithSideBarre>
				</Container>
			</Layout>
		</>
	);
};

export default WatchSingleVideo;
