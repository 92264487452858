/**
 *  Rôle : Utilise la création de route dynamique des vidéos & catégories videos
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Router } from '@reach/router';
import WatchSingleVideo from '../components/componentPage/WatchSingleVideo';
import WatchCategoryVideo from '../components/componentPage/WatchCategoryVideo';
import Redirect from '../utils/Redirect';
import Layout from '../components/body/layoutRouter';

const watch = () => (
	<Layout>
		<Router>
			<Redirect path="watch-video" />
			<Redirect path="watch-video/category/" />
			<WatchSingleVideo path="watch-video/:id" />
			<WatchCategoryVideo path="watch-video/category/:id" />
		</Router>
	</Layout>
);

export default watch;
