/**
 *  Rôle : Page catégories des Vidéos
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState } from 'react';
import StickyBox from 'react-sticky-box';
import Container from '../body/Container';
import Layout from '../body/layout';
import Seo from '../seo';
import ContainerWithSideBarre from '../body/ContainerWithSideBarre';
import ContentLeft from '../body/ContentLeft';
import ContentRight from '../body/ContentRight';
import SideBar from '../marketing/sideBar/SideBar';
import ListeLastVideosByCategory from '../content/ListeLastVideosByCategory';
import ListeCategory from '../content/ListeCategoriesVideos';
import Loader from '../functional/Loader';

const WatchCategoryVideos = (props) => {
	const { id } = props;
	const [titlePage, setTitlePage] = useState();

	const getTitleCategory = (title) => {
		setTitlePage(title);
	};

	return (
		<Layout title={titlePage ? titlePage : <Loader color={'white'} />}>
			<Seo title={titlePage ? titlePage : 'Catégories des videos de Nader Abou Anas'} />
			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<ContentLeft backgroundDisplay={false}>
							<ListeLastVideosByCategory
								idCat={id}
								getTitleCategory={getTitleCategory}
							/>
						</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<StickyBox offsetTop={20} offsetBottom={20} className="stickyBox">
								<ListeCategory />
								<SideBar modCategoriesDisplay={false} />
							</StickyBox>
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default WatchCategoryVideos;
