/**
 * Redirection
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Redirect } from '@reach/router';

export default function Redirection() {
	return <Redirect noThrow to="/videos/" />;
}
