/**
 * Global Layout
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';

const LayoutRouter = (props) => {
	let { children, content, animate } = props;

	// Animation Main
	let main;
	if (animate === false) {
		main = <main>{children}</main>;
	} else {
		main = (
			<main
			// data-aos="fade"
			>
				{children}
			</main>
		);
	}

	return (
		<div id="layout" className="mx-auto my-0" style={{ maxWidth: `${content}` }}>
			{main}
		</div>
	);
};

export default LayoutRouter;
